import React from 'react';
import { Container } from 'react-bootstrap';
import classNames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'gatsby'

/**
 * Components
 */
import Property from '@components/property/property';

const FeaturedProperty = (props, {className}) => {
  return (
    <div className={classNames('overflow-hidden featured-property', className)}>
      <div className="property-recommend">
        <Container>
          <h4 className="text-md-center">Featured Properties in {props.Title}</h4>
          <p className="text-md-center">Want to buy or rent? Search <Link to="">Property for Sale in {props.Title}</Link> or <Link to="">Property for Rent in {props.Title}</Link></p>
          <Slider
            slidesToShow={3}
            responsive={[
              {
                breakpoint: 980,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000
                }
              },
              {
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                  variableWidth: false,
                  autoplay: false,
                  speed: 1000,
                  nextArrow: <span aria-hidden="true" class="carousel-control-next-icon"></span>,
                  prevArrow: <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                }
              }
            ]}
          >
            <Property property={{
                image: '/images/p1.jpg',
                price: '£510,000',
                location: 'Bingley, West Yorkshire',
                description: '4 bedroom house for sale'
              }} 
            />
            <Property property={{
                image: '/images/p2.jpg',
                price: '£510,000',
                location: 'Bingley, West Yorkshire',
                description: '4 bedroom house for sale'
              }} 
            />
            <Property property={{
                image: '/images/p3.jpg',
                price: '£510,000',
                location: 'Bingley, West Yorkshire',
                description: '4 bedroom house for sale'
              }} 
            />
          </Slider>
        </Container>
      </div>
    </div>
  )
}

export default FeaturedProperty;
