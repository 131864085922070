import React, { useState } from 'react';
import { Container, Row, Col, Button, Accordion, Tabs, Tab, Form, Card } from 'react-bootstrap';
import classNames from 'classnames';
import ReactMarkdown from "react-markdown"

/**
 * Asset
 */
import './assets/styles/_index.scss';

/**
 * Components
 */
import StickyNav from '@components/sticky-nav/sticky-nav-office';
// import Map from '@components/map/map';
import LocRatingMap from '@components/map/loc-rating-map';
import Reviews from '@components/reviews/reviews';
import BookVideo from '@components/book-video/book-video';
import FeaturedProperty from '@components/featured-property/featured-property';
import TeamSlider from "@components/team/office-team";
import { MapMarkerInfoBox } from '@components/map/map-marker';

const OfficeInfo = (props, {className}) => {

  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat']  = props.data.Latitude;        
  mapItem['lng']  = props.data.Longitude;
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox(props.data.Office_Name + props.data.Address, mapMarkerImageShowFlag);
  console.log( mapItemHtml );
  mapItem['html'] = mapItemHtml;
  mapItems.push( mapItem );

  return (
    <div className={classNames('property-info office-details-info', className)}>
      <StickyNav />
      <Container id="details" className="details">
        <Row>
          <Col lg={7}>

            <ReactMarkdown source={props.data.About_Office} />           
            
          </Col>
          <Col lg={{span: 4, offset: 1}} className="align-self-start sidebar-sticky">
            <Card>
              <Row className="align-items-center">
                <Col md={6} lg={12}>
                  <Card.Img variant="top" src={props.data.Office_Image.publicURL} alt="store" />
                </Col>
                <Col md={6} lg={12}>
                  <Card.Body>
                    <h5>Office Details</h5>
                    <h6>Contact</h6>
                    <p><ReactMarkdown source={props.data.Address} /></p>
                    <p><a href={`tel:+${props.data.Phone}`}>{props.data.Phone}</a> / <a href={`mailto:${props.data.Email}`}>Email Office</a></p>
                    <div className="hours">
                      <h6>Opening Hours</h6>
                      <p><ReactMarkdown source={props.data.Opening_Hours} /></p>
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>

      <div id="map" className="map">
        {/* <Map /> */}
        <LocRatingMap data={mapItems} />
      </div>

      <div id="reviews" className="reviews">
        <Reviews />
      </div>

      <div id="team" className="team">
        <TeamSlider Title={`${props.data.Office_Name} Team`} data={props.Team}/>
      </div>

      <BookVideo />

      <div id="property" className="property">
        <FeaturedProperty Title={props.data.Office_Name}/>
      </div>

    </div>
  )
}

export default OfficeInfo;
