import React from "react"
import { openPopupWidget } from 'react-calendly';
import { Button } from 'react-bootstrap';
 
const CalendlyOne = ({ url, prefill, pageSettings, utm }) => {
  const onClick = () => openPopupWidget({ url, prefill, pageSettings, utm });
 
  return <Button onClick={onClick} className="btn btn-primary" >Book a Video Call</Button>
}

export default CalendlyOne;
