import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { isMobile, isTablet } from "react-device-detect";
import BackgroundImage from 'gatsby-background-image-es5';

/**
 * Assets
 */
import './assets/styles/_index.scss';
import Mute from './assets/img/mute.svg';


const NoVideoEle = ({ video, image, autoplay, mute, align, height, width, contain, imageContain, mobileHeight, className }) => {
  const [ isContained, setIsContained ] = useState(false);
  const [ playing, setPlaying ] = useState(false);
  const [ pause, setPause ] = useState(false);
  const [ muted, setMuted ] = useState(false);

  const [ currentHeight, setCurrentHeight ] = useState(height);


  useEffect(() => {
    if(contain){
      setIsContained(true);
    }
    if((isMobile || isTablet) && mobileHeight){
      setCurrentHeight(mobileHeight)
    } else if(isMobile) {
      setCurrentHeight('auto')
    }
  }, [autoplay, mute, contain, mobileHeight]);


  return (
    <div 
      className={classNames(`video video--${align}`, className, {'is-contained': isContained, 'is-playing': playing})}  
      style={{'height': currentHeight}}
    >
      <BackgroundImage
        Tag="section"
        fluid={image}
        style={{'width': isContained ? '100%' : width }}
        className={classNames('video__inner', {'contain-image': imageContain})} 
        fadeIn={true}
      />
    </div>
  )
}

export default NoVideoEle;
