import React from 'react';
import { Container, Nav } from 'react-bootstrap';
import Scrollspy from 'react-scrollspy';
import classNames from 'classnames';

/**
 * Assets
 */
import './assets/styles/_index.scss';

const links = [
  {
    text: 'Details',
    url: '#details',
    isSpy: true
  },
  {
    text: 'Location',
    url: '#map',
    isSpy: true
  },
  {
    text: 'Reviews',
    url: '#reviews',
    isSpy: true
  },
  {
    text: 'Team',
    url: '#team',
    isSpy: true
  },
  {
    text: 'Property',
    url: '#property',
    isSpy: true
  }
];

function getOffset( el ) {
  let _x = 0;
  let _y = 0;
  while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
  }
  return { top: _y, left: _x };
}

const StickyNavOffice = ({active, className}) => {
  const scrollHandle = (event) => {
    event.preventDefault();
    const offestTop = getOffset(document.querySelector(event.target.getAttribute('href'))).top;
    const stickyHeight = document.querySelector('.sticky-nav').clientHeight;
    window.scrollTo({
      top: offestTop - stickyHeight,
      behavior: 'smooth'
    });
  }
  return (
    <div className={classNames('sticky-nav sticky-top', className)}>
      <Container>
        <Scrollspy
          items={["details", "map", "reviews", "team", "property"]}
          offset={-96}
          componentTag={Nav}
          currentClassName="is-active"
        >
            {links.map((link, ind) => (
              <Nav.Link key={ind} href={link.url} onClick={link.isSpy ? scrollHandle : ''}>{link.text}</Nav.Link>
            ))}
        </Scrollspy>
      </Container>
    </div>
  )
}

export default StickyNavOffice;
