import React from 'react';
import classNames from 'classnames';

/**
 * Assets
 */
import './assets/styles/_index.scss';
import StarRating from '@static/svg/star_rating.svg';

const ReviewSlide = ({slide, className}) => {
  return (
    <div className={classNames('review-slide', className)}>
      <div className="review-slide__icon">
        <StarRating />        
      </div>
      <p>{slide.text}</p>
      <span className="review-slide__author">{slide.author}</span>
    </div>
  )
}

export default ReviewSlide
