import React from "react";
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import { StaticQuery, graphql } from 'gatsby';
import ReactMarkdown from "react-markdown"


/**
 * Components
 */
import ReviewSlider from '@components/reviews-slider/reviews-slider';


const Reviews = (props) => {
  return (
    <Container 
      className={classNames(classNames)}
      fluid={true}
    >
      <Row className="rating">
        <Col className="is-static text-center">
          <ReactMarkdown source={props.data.strapiGlobalConfig.Global_Reviews_Header} />
          <ReviewSlider />
        </Col>
      </Row>
    </Container>
  )
}

export default function ReviewsGlobal() {
  return (
    <StaticQuery
      query={graphql`
      query {

        strapiGlobalConfig {
          Global_Reviews_Header
        }

      }
    `}
      render={data => <Reviews data={data} />}
    />
  )
}